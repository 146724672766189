export enum AllowedLanguages {
  En = "en",
  Fr = "fr",
  Nl = "nl",
}

export enum AllowedJobSearchParams {
  City = "city",
  ContractType = "type",
  Job = "related",
  Language = "language",
  Lat = "lat",
  Lng = "lng",
  MaxDistance = "max_distance",
  Niche = "speciality",
  Office = "o",
  Page = "page",
  Query = "query",
  Regime = "regime",
  Sector = "sector_group",
  functionGroup = "function_group",
}

export enum AllowedBlogOverviewSearchParams {
  Language = "language",
  Page = "page",
  BlogCategories = "blog_categories",
}

export enum AllowedOfficeSearchParams {
  City = "city",
  Lat = "lat",
  Lng = "lng",
  MaxDistance = "max_distance",
  Niche = "speciality",
  Page = "page",
}

export enum AvailableTargetAudience {
  EMPLOYEE = "EMPLOYEE",
  EMPLOYER = "EMPLOYER",
}

export interface Translation {
  language: AllowedLanguages;
  url: string;
}

export interface StandardPageProps {
  params: {
    locale: AllowedLanguages;
    path: string[];
  };
  searchParams: Record<string, string>
}

export interface SearchHistoryEntry {
  city?: string;
  max_distance?: string;
  query?: string;
}

export interface FilterValue {
  count?: number;
  value: string;
}

export interface FilterType {
  name: string;
  values: FilterValue[];
}

export interface Session {
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    name: string;
  };
}

export interface PathObject {
  en: string,
  fr: string,
  nl: string,
}

export enum AllowedFaqOverviewSearchParams {
  Language = "language",
  Page = "page",
  Search = "search",
  TargetAudience = "target_audience",
}

export enum AllowedFaqCategoryOverviewSearchParams {
  Language = "language",
  Page = "page",
  CategoryUUID = "category_uuid",
}

// export interface Token extends Session {
//   email: string;
//   exp: number;
//   iat: number;
//   id: string;
//   jti: string;
//   name: string;
//   sub: string;
// }
