import { cn } from "@/helpers/className";

export type Icons =
  | "angle-down"
  | "angle-left"
  | "angle-right"
  | "arrow-right"
  | "arrow-right-light"
  | "article"
  | "bell"
  | "briefcase"
  | "calendar"
  | "chat"
  | "check"
  | "clock"
  | "close"
  | "coffee"
  | "coin"
  | "confetti"
  | "contract"
  | "contact"
  | "couch"
  | "delete"
  | "download"
  | "dot"
  | "eye"
  | "faq"
  | "facebook"
  | "fingerprint"
  | "fire"
  | "flag"
  | "gear"
  | "hammer"
  | "handshake"
  | "hashtag"
  | "heart"
  | "history"
  | "info"
  | "lifebuoy"
  | "list"
  | "linkedin"
  | "loader"
  | "location"
  | "love"
  | "love-fill"
  | "magnify"
  | "mail"
  | "map"
  | "map-pin"
  | "paperplane"
  | "pause"
  | "phone"
  | "play"
  | "play-circle"
  | "play-fill"
  | "plus"
  | "profile"
  | "question"
  | "search"
  | "share"
  | "sign-out"
  | "sliders"
  | "tag"
  | "twitter"
  | "users"
  | "vacation"
  | "warning"
  | "wave"
  | "workid";

interface IconProps {
  className?: string;
  name: Icons;
}

const Icon: React.FC<IconProps> = ({ name, className = "" }) => {
  const classNames = cn("fill-current", className);
  return (
    <svg className={classNames} aria-hidden="true">
      <use href={`/img/icons.svg#${name}`} />
    </svg>
  );
};

export default Icon;
