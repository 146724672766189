import { create } from "zustand";

import { Translation } from "@/types/generic";

interface UsePageTranslationsPaths {
  pageTranslationsPaths: Translation[];
  setPageTranslationsPaths: (translations: Translation[]) => void;
}

export const usePageTranslationsPaths = create<UsePageTranslationsPaths>(
  (set) => ({
    pageTranslationsPaths: [],
    setPageTranslationsPaths: (translations) => {
      set(() => ({ pageTranslationsPaths: translations }));
    },
  }),
);
