export const officePaths = {
  nl: "kantoren",
  fr: "agences",
  en: "offices",
};

export const blogPaths = {
  nl: "blog",
  fr: "blog",
  en: "blog",
};

export const faqPaths = {
  nl: "faq",
  fr: "faq",
  en: "faq",
};

export const registerPaths = {
  nl: "registreer",
  fr: "enregistrez-vous",
  en: "register",
};

export const forgotPasswordPaths = {
  nl: "reset-uw-wachtwoord",
  fr: "reinitialisez-mot-de-passe",
  en: "reset-password",
};

export const resetPasswordPaths = {
  nl: "pas-wachtwoord-aan",
  fr: "modifier-mot-de-passe",
  en: "change-password",
};

export const choosePasswordPaths = {
  nl: "kies-wachtwoord",
  fr: "choisissez-mot-de-passe",
  en: "choose-password",
};

export const activateUserPaths = {
  nl: "activeer-gebruiker",
  fr: "activez-compte",
  en: "activate-user",
};

export const applyPaths = {
  nl: "solliciteer",
  fr: "postulez",
  en: "apply",
};

export const applyStep2Paths = {
  nl: "stap-2",
  fr: "partie-2",
  en: "step-2",
};

export const applySuccessPaths = {
  nl: "succes",
  fr: "succes",
  en: "success",
};

export const messagesPaths = {
  nl: "berichten",
  fr: "messages",
  en: "messages",
};

export const availabilityPaths = {
  nl: "mijn-beschikbaarheid",
  fr: "ma-disponibilite",
  en: "my-availability",
};

export const documentsPaths = {
  nl: "mijn-documenten",
  fr: "mes-documents",
  en: "my-documents",
};

export const profilePaths = {
  nl: "mijn-profiel",
  fr: "mon-profil",
  en: "my-profile",
};

export const applicationsPaths = {
  nl: "mijn-sollicitaties",
  fr: "mes-candidatures",
  en: "my-applications",
};

export const preferencesPaths = {
  nl: "mijn-voorkeuren",
  fr: "mes-preferences",
  en: "my-preferences",
};

export const newsletterPreferencesPaths = {
  nl: "nieuwsbrief-voorkeuren",
  fr: "bulletin-preferences",
  en: "nesletter-preferences",
};

export const savedJobsPaths = {
  nl: "bewaarde-vacatures",
  fr: "offres-sauvegardees",
  en: "saved-jobs",
};

export const intentAgreementPaths = {
  nl: "myvivaldis/documenten-ondertekenen/intentieverklaring",
  fr: "myvivaldis/signer-des-documents/declaration-dintention",
  en: "myvivaldis/sign-documents/intent-agreement",
};

export const studentAgreementPaths = {
  nl: "myvivaldis/documenten-ondertekenen/studentenverklaring",
  fr: "myvivaldis/signer-des-documents/declaration-de-letudiant",
  en: "myvivaldis/sign-documents/student-agreement",
};
