import { AllowedLanguages } from "@/types/generic";

export const fallbackLng: AllowedLanguages = AllowedLanguages.Nl;
export const languages = [
  fallbackLng,
  AllowedLanguages.Fr,
  AllowedLanguages.En,
] as const;
export const defaultNS = "translation";
export const cookieName = "language";

export const getOptions = (
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) => {
  return {
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
};
